<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'SMS'" :subtitle="$t('generic-str.send')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-7">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs primary-tabs">
                <li class="nav-item nav-m-custom" role="presentation">
                  <a href="javascript:void(0)" class="nav-link" :class="{ 'active': this.form.type == 'simple' }"
                    v-tab="'simple'" data-toggle="tab" aria-expanded="true" @click="changeType('simple')">
                    <span class="material-symbols-outlined"> edit </span>
                    <span>{{ $t('sms.send-msg.tabs.simple.title') }}</span>
                    <!-- Simples -->
                  </a>
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a href="javascript:void(0)" class="nav-link" :class="{ 'active': this.form.type == 'contacts' }"
                    v-tab="'contact'" aria-expanded="true" @click="changeType('contacts')">
                    <i class="far fa-address-book"></i><span>{{ $tc('sms.send-msg.tabs.contact.title', 1) }}</span></a>
                  <!-- Contato -->
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a href="javascript:void(0)" class="nav-link" v-tab="'group'" aria-expanded="true"
                    :class="{ 'active': form.type == 'groups' }" @click="changeType('groups')">
                    <span class="material-symbols-outlined"> group </span>
                    <span>{{ $t('sms.send-msg.tabs.groups.title') }}</span>
                    <!-- Grupos -->
                  </a>
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a href="javascript:void(0)" class="nav-link" v-tab="'files'" aria-expanded="true"
                    @click="changeType('files')">
                    <span class="material-symbols-outlined"> file_upload </span>
                    <span>{{ $t('sms.send-msg.tabs.archives.title') }}</span>
                    <!-- Arquivos -->
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div v-if="shortCodes.length >= 1" class="form-group row sender-group">
                        <div class="col-lg-12 template-wrapper">
                          <label class="control-label text-left col-12 col-sm-3 pl-0">
                            {{ $t('sms.send-msg.lbl-sender') }}:
                            <!-- Remetente -->
                          </label>
                          <select-input label="name" class="template-style" :reduce="(shortCode) => shortCode.id"
                            v-model="form.shortCodeId" :options="shortCodes" :clearable="false"
                            v-on:option:selected="changeProduct" />
                        </div>
                      </div>
                      <!-- Skeleton loading container -->
                      <div v-else class="container">
                        <div class="row" style="gap: 30px;">
                          <div class="col-2 animated-background h-30 mb-5"></div>
                          <div class="col-4 animated-background h-30 mb-5"></div>
                        </div>
                      </div>
                      <!--/Skeleton loading container -->
                      <!--<div class="form-group row">
                    <label class="control-label text-left col-3">
                      Produto
                    </label>
                    <div class="col-md-6">
                      <select-input
                        label="name"
                        :reduce="(product) => product.id"
                        v-model="form.productId"
                        :options="products"
                        :clearable="false"
                        v-on:option:selected="changeProduct"
                      />
                    </div>
                  </div>-->
                      <div v-if="form.type != 'simple'" class="form-group row campaign-group">
                        <label class="control-label text-left col-12 col-sm-3">
                          {{ $t('sms.send-msg.lbl-campaign') }}:
                          <!-- Nome da Campanha -->
                        </label>
                        <div class="col-md-9">
                          <input v-model="form.name" class="form-control campaign-input" />
                        </div>
                      </div>
                      <div class="tab-content">
                        <div class="tab-pane" id="simple" :class="{ 'active': this.form.type == 'simple' }">
                          <div class="row">
                            <div class="col-lg-12 template-wrapper">
                              <label class="mr-3 col-12 col-sm-3 pl-0">Template: </label>
                              <template-input v-model="form.template" @input="insertTemplate" />
                            </div>
                          </div>
                          <div class="form-group row">
                            <!-- Destinatários -->
                            <div class="col-lg-12 d-flex align-items-center flex-wrap recipient-row">
                              <label class="mr-3 mb-0">{{ $t('sms.send-msg.tabs.simple.lbl-recipient',) + ': '
                                }}</label>
                              <textarea id="recipient-area" v-model="form.to" rows="1" push-tags label="tags"
                                class="form-control col" v-bind:class="{
                                  invalid:
                                    submited &&
                                    form.to.length == 0 &&
                                    form.type == 'simple',
                                }" :placeholder="this.$t(
                                  'sms.send-msg.tabs.simple.lbl-example',
                                )
                                  " />
                            </div>
                            <!-- <div class="col-md-12 pl-0">
                              <div class="form-floating floating-transparent">
                                <textarea
                                  id="recipient-area"
                                  v-model="form.to"
                                  rows="1"
                                  push-tags
                                  label="tags"
                                  class="form-control"
                                  v-bind:class="{
                                    invalid:
                                      submited &&
                                      form.to.length == 0 &&
                                      form.type == 'simple',
                                  }"
                                  :placeholder="
                                    this.$t(
                                      'sms.send-msg.tabs.simple.lbl-example',
                                    )
                                  "
                                />
                                <label class="text-left">{{$t('sms.send-msg.tabs.simple.lbl-recipient',) + ': '}}</label>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="tab-pane" id="contact" :class="{ 'active': this.form.type == 'contacts' }">
                          <div class="form-group row">
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label class="control-label text-left col-4 pl-0">{{
                                  $tc('sms.send-msg.tabs.contact.title', 2)
                                  }}:</label>
                                <!-- Contatos -->
                                <button class="btn btn_add" type="button" v-modal="{ target: 'contact-send-modal' }">
                                  <span class="material-symbols-outlined add_person">
                                    person_add
                                  </span>
                                  <!-- Adicionar Contato -->
                                </button>
                                <hr />
                                <b-collapse id="collapse-1" visible class="mt-2 col-lg-12">
                                  <b-card class="send-group-card">
                                    <div class="text-center table-placeholder" v-if="contacts.length == 0">
                                      <!-- <i class="fas fa-user font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.contact.lbl-none-contact',
                                          )
                                        }}
                                        <!-- Nenhum contato adicionado -->
                                      </h5>
                                    </div>
                                    <p class="card-text" v-if="contacts.length > 0">
                                      {{
                                        $t(
                                          'sms.send-msg.tabs.contact.lbl-added-contacts',
                                        )
                                      }}
                                      ({{ contacts.length }})
                                    </p>
                                    <!-- Contatos adicionados -->
                                    <div class="overflow-auto pb-2">
                                      <ul class="ul-list-groups" v-if="contacts.length > 0">
                                        <li v-for="(contact, i) in contacts" :key="contact.id">
                                          <div class="badge badge-custom fade show" role="alert">
                                            {{ contact.first_name }} ({{ contact.mobile_number | phone }})
                                            <button type="button" class="close" @click="removeContact(i)">
                                              <span class="material-symbols-outlined">close</span>
                                            </button>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="group" :class="{ active: form.type == 'groups' }">
                          <div class="form-group row">
                            <!-- Grupos -->
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label class="control-label text-left col-4 pl-0">{{
                                  $t('sms.send-msg.tabs.groups.title')
                                  }}:</label>
                                <button class="btn btn_add" type="button" v-modal="{ target: 'group-send-modal' }">
                                  <span class="material-symbols-outlined add_person">
                                    group_add
                                  </span>
                                  <!-- Adicionar Grupo -->
                                </button>
                                <hr />
                                <b-collapse id="collapse-1" visible class="mt-2 col-lg-12">
                                  <b-card class="send-group-card">
                                    <div class="text-center table-placeholder" v-if="groups.length == 0">
                                      <!-- <i class="fas fa-users font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.groups.lbl-none-group',
                                          )
                                        }}
                                        <!-- Nenhum grupo adicionado -->
                                      </h5>
                                    </div>
                                    <p class="card-text" v-if="groups.length > 0">
                                      {{
                                        $t(
                                          'sms.send-msg.tabs.groups.lbl-added-groups',
                                        )
                                      }}
                                      ({{ groups.length }})
                                      <!-- Grupos adicionados -->
                                    </p>
                                    <ul class="ul-list-groups" v-if="groups.length > 0">
                                      <li v-for="(group, i) in groups" :key="group.id">
                                        <div class="
                                            alert
                                            alert-secondary
                                            alert-outline
                                            alert-dismissible
                                            fade
                                            show
                                          " role="alert">
                                          {{ group.name }}
                                          <button type="button" class="close" @click="removeGroup(i)">
                                            <span aria-hidden="true" class="fas fa-times"></span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="files">
                          <div class="form-group row">
                            <!-- Adicionar Arquivo -->
                            <div v-if="!importing" class="col-md-12 p-0 add-contacts">
                              <label class="control-label text-left col-12 col-sm-3">{{
                                $t('sms.send-msg.tabs.archives.lbl-add-archive')
                              }}:</label>
                              <input ref="uploadedFile" type="file" accept=".csv" hidden @change="uploadFile" />
                              <div class="col-lg-9 archive-opt">
                                <div class="d-flex align-items-center flex-wrap">
                                  <div class="form-control form-file">
                                    {{ form.file }}
                                  </div>
                                  <button class="btn btn-import" type="button" @click="openFileUpload">
                                    <template v-if="form.file">{{
                                      $t(
                                        'sms.send-msg.tabs.archives.lbl-change-archive',
                                      )
                                    }}</template>
                                    <!-- Trocar Arquivo -->
                                    <template v-else><span
                                        class="
                                          material-symbols-outlined
                                          icon_title
                                        ">file_upload</span><!-- {{$t('sms.send-msg.tabs.archives.lbl-select-archive')}} --></template>
                                    <!-- Selecionar Arquivo -->
                                  </button>
                                </div>
                                <!-- Tooltip: Layout do arquivo para importação. -->
                                <a class="sample-area d-flex align-items-center" href="/exemplos/example.csv"
                                  download="example.csv"
                                  v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')">
                                  <!-- {{$t('generic-str.example')}} -->
                                  <span class="material-icons-outlined">file_download</span>
                                  example.csv
                                </a>
                              </div>
                            </div>
                            <div v-else class="col-md-6">
                              <div class="row">
                                <div class="col-12">
                                  <small>{{
                                    $t(
                                      'sms.send-msg.tabs.archives.lbl-validating',
                                    )
                                  }}</small>
                                  <!-- O arquivo está sendo validado -->
                                </div>
                              </div>
                              <div class="progress">
                                <div class="
                                    progress-bar
                                    progress-bar-striped
                                    progress-bar-animated
                                  " role="progressbar" :style="`width: ${uploadProgress}%;`" aria-valuenow="25"
                                  aria-valuemin="0" aria-valuemax="100">
                                  {{ uploadProgress }}%
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-md-3"> -->
                            <!-- Tooltip: Layout do arquivo para importação. -->
                            <!-- <a
                                href="/exemplos/example.csv"
                                class="btn btn-bulk btn-success"
                                v-tooltip.top="
                                  $t(
                                    'sms.send-msg.tabs.archives.lbl-tooltip-archive',
                                  )
                                "
                                >{{ $t('generic-str.example') }}</a
                              >
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <sms-input v-model="form.body" v-on:changeBody="updateBody($event)" :normalize="form.normalize"
                        :variables="vars" />
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12" style="display: none">
                      <!--<div v-if="selectedProduct && selectedProduct.plan.credits" class="card">
                    <div class="card-body">
                      <div
                        class="icon-rounded icon-rounded-info float-left m-r-20"
                      >
                        <i class="la la-paper-plane f-w-600"></i>
                      </div>
                      <h5 class="card-title m-b-5">
                        {{ selectedProduct.plan.credits }}
                      </h5>
                      <h6 class="text-muted m-t-10">Creditos</h6>
                    </div>
                  </div>-->
                      <!-- Options <div> -->
                      <div class="card card-border-info">
                        <div class="card-header p-l-20 p-b-15 p-t-15">
                          {{ $t('options-div.title') }}
                        </div>
                        <div class="card-body">
                          <div class="form-group row">
                            <div class="col-md-12">
                              <div class="
                                  custom-control
                                  custom-checkbox
                                  custom-control-inline
                                  custom-control-inline-send
                                ">
                                <input class="tgl tgl-light tgl-primary" id="normalized" type="checkbox"
                                  v-model="form.normalize" name="normalized" @change="countSegments" />
                                <label class="tgl-btn" for="normalized"></label>
                                <label class="label-send-check">{{
                                  $t('options-div.lbl-replace-chars')
                                }}</label>
                                Substituir Caracteres Especiais
                              </div>
                            </div>
                          </div>
                          <!--<div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="custom-control custom-checkbox custom-control-inline custom-control-inline-send"
                          >
                            <input
                              class="tgl tgl-light tgl-primary"
                              id="flash"
                              type="checkbox"
                              v-model="form.flash"
                              name="flash"
                              :disabled="unicode"
                            />
                            <label class="tgl-btn" for="flash"></label>
                            <label class="label-send-check">Enviar como flash</label>
                            <span
                              class="h5"
                              v-tooltip.top="'A mensagem será exibida no telefone imediatamente sem salvar nele. Sujeito a disponibilidade da operadora.'"
                            >
                              <i class="la la-question-circle"></i>
                            </span>
                          </div>
                        </div>
                      </div>-->
                          <div class="form-group row">
                            <div class="col-md-12">
                              <div class="
                                  custom-control
                                  custom-checkbox
                                  custom-control-inline
                                  custom-control-inline-send
                                ">
                                <input class="
                                    tgl tgl-light tgl-primary
                                    calendar-change
                                  " id="schedule" type="checkbox" v-model="form.schedule" name="schedule"
                                  @change="changeSchedule" />
                                <label class="tgl-btn" for="schedule"></label>
                                <label class="label-send-check">{{
                                  $t('options-div.lbl-schedule')
                                }}</label><!-- Agendar -->
                              </div>
                              <date-picker v-if="form.schedule" v-model="form.datetime" :config="options"
                                class="form-control input-date"
                                :placeholder="$t('generic-str.date-hour')"></date-picker>
                            </div>
                          </div>
                          <hr class="dashed" />
                        </div>
                      </div>
                      <!--/Options <div> -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer">
              <div class="card-footer-wrapper">
                <ul class="list-unstyled send-opt">
                  <li>
                    <div class="control-group opt-2">
                      <label class="control control-checkbox" for="normalized">
                        <input type="checkbox" id="normalized" v-model="form.normalize" name="normalized"
                          @change="countSegments" />
                        <div class="control_indicator"></div>
                        <span>{{ $t('options-div.lbl-replace-chars') }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
                <div class="right-items">
                  <!-- <span class="material-symbols-outlined title attach">
                    attach_file
                  </span>
                  <i class="fas fa-smile title"></i> -->
                  <div class="calendar_form">
                    <date-picker v-if="form.schedule" v-model="form.datetime" :config="options" v-b-tooltip.hover
                      :title="form.datetime" class="form-control input-date"
                      :placeholder="$t('generic-str.date-hour')"></date-picker>
                  </div>
                  <span class="material-icons text-yup calendar_icon" @click="this.form.schedule = true">
                    watch_later
                  </span>
                  <button @click="send" type="button" :class="{
                    'qt-loader qt-loader-mini qt-loader-right': isSending,
                  }" :disabled="isSending || importing" class="btn btn-yup-purple float-right ml-3">
                    {{ $t('generic-str.send') }}
                  </button>
                  <!-- <b-dropdown
                    @click="send"
                    right
                    dropup
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                    }"
                    :disabled="isSending || importing"
                    split
                    variant="yup-purple"
                    :text="$t('generic-str.send')"
                  >
                    <template #button-content>
                      {{ $t('generic-str.send') }}
                    </template>
                    <b-dropdown-item v-model="form.birthDate"
                      >{{ $t('options-div.schedule-delivery') }}
                      <span class="material-icons">
                        watch_later
                      </span></b-dropdown-item
                    >
                  </b-dropdown> -->
                </div>
                <!-- <button
              @click="send"
              type="button"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending || importing"
              class="btn btn-yup-purple ml-auto"
            >
              {{ $t('generic-str.send') }}
            </button> -->
                <!-- Enviar -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div class="card-body">
                <p class="card-text" v-if="!text_body_content">
                  Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                  dia xxx.
                </p>
                <p class="card-text" v-else>{{ text_body_content }}</p>
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import SmsService from '@/services/sms.service';
import NumberService from '@/services/number.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import SelectInput from '@/components/SelectInput.vue';
import TemplateInput from '@/components/rebranding/sms/TemplateInput.vue';
import SmsInput from '@/components/rebranding/form/SmsInput.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

const GSM = /^[ -~\n]*$/;

export default {
  name: 'SendSms',
  components: {
    PageHeader,
    TemplateInput,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    datePicker,
    SelectInput,
    SmsInput,
    LottieAnimNoData,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/send',
      },

      numbers: [],
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      files: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      uploadProgress: 0,
      vars: [],
      fileVariables: [],
      shortCodes: [],
      selectedProduct: [],
      form: {
        type: 'simple',
        productId: '',
        shortCodeId: '',
        to: '',
        body: '',
        file: '',
        normalize: false,
        flash: false,
        template: null,
        birthDate: '',
        schedule: true,
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
      text_body_content: '',
    };
  },
  computed: {
    classes() {
      return ['wizard'];
    },
  },
  created() {
    this.fetchShortCodes();
    // this.fetchProducts()
    if (this.$route.params.contacts) {
      this.contacts = this.$route.params.contacts;
      this.contacts.forEach((contact) => {
        this.selectedContacts.push(contact.id);
      });
      this.form.type = 'contacts';
    }
    if (this.$route.params.groups) {
      this.groups = this.$route.params.groups;
      this.groups.forEach((group) => {
        this.selectedGroups.push(group.id);
      });
      this.form.type = 'groups';
    }
  },
  methods: {
    // Update preview body message
    updateBody(e) {
      this.text_body_content = e;
    },
    fetchShortCodes() {
      SmsService.getAccountShortCodes().then(
        (shortCodes) => {
          this.shortCodes = shortCodes;
          if (shortCodes) {
            this.form.shortCodeId = shortCodes[0].id;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.selectedProduct = product;
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' }, // Primeiro Nome
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' }, // Sobrenome
          {
            name: this.$t('generic-str.lbl-cellphone'),
            value: 'mobile_number',
          }, // Celular
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        self.fileVariables = [];
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
        self.vars = self.fileVariables;
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    smsTextChange() {
      let contVar = false;
      if (this.type === 'files') {
        this.fileVariables.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      } else {
        this.vars.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    changeSchedule() { },
    countSegments(newValues) {
      this.smsTextChange();
      const newValue = Utils.isNumber(newValues) ? newValues : 0;
      // this.segments =
      this.length = this.form.body.length + newValue;

      if (this.form.body.match(GSM) || this.form.normalize) {
        this.unicode = false;
      } else this.unicode = true;

      if (this.unicode) {
        this.form.flash = null;
        this.segments = this.length <= 70 ? 1 : Math.ceil(this.length / 67);
      } else {
        this.segments = this.length <= 160 ? 1 : Math.ceil(this.length / 153);
      }
    },
    insertTemplate(template) {
      this.form.body = template;
      this.countSegments();
      // form.body = e.target.value;
    },
    send() {
      this.submited = true;
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      switch (this.form.type) {
        case 'simple':
          this.form.contacts = [];
          this.form.groups = [];

          if (this.form.to.length > 0 && this.form.body.length > 0) {
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.missing'),
            });
          }
          break;
        case 'contacts':
          if (this.selectedContacts.length > 0 && this.form.body.length > 0) {
            this.form.contacts = this.selectedContacts;
            this.form.to = '';

            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.contact'),
            });
          }
          break;
        case 'groups':
          this.form.contacts = [];
          this.form.to = '';

          if (this.selectedGroups.length > 0 && this.form.body.length > 0) {
            this.form.groups = this.selectedGroups;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.group'),
            });
          }
          break;
        case 'files':
          if (this.form.file && this.form.body.length > 0) {
            this.form.files = this.selectedFiles;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.archive'),
            });
          }
          break;
        default:
      }
    },
    sendRequest() {
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          SmsService.sendSms(this.form)
            .then(
              (send) => {
                /* if (this.form.type === 'simple') {
                  this.$router.push({
                    name: 'SmsSuccess',
                    params: { sms: send.data },
                  });
                } else {
                  this.$router.push('/sms/campaigns');
                } */
                this.form = {
                  type: 'simple',
                  productId: '',
                  shortCodeId: '',
                  to: '',
                  body: '',
                  file: '',
                  normalize: false,
                  flash: false,
                  template: null,
                  birthDate: '',
                  schedule: true,
                };
                this.changeType('simple');
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: this.$t('warnings.send-component.msg-sent'),
                  type: 'success',
                });
                this.isSending = false;
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.response.data.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            )
            .catch((error) => {
              console.log(error.response);
              this.isSending = false;
            });
        }
      });
    },
    putEmoji(emoji) {
      if (this.form.body.length) {
        this.form.body += ` ${emoji}`;
      } else {
        this.form.body = emoji;
      }
      this.countSegments();
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
  },
};
</script>

<style scoped lang="scss">
textarea {
  resize: none !important;
}

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: unset;
}

/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
.input-date {
  margin-top: 15px !important;
}

.form-control.invalid {
  border-color: #ff5c75;
}

.actions-var {
  position: relative !important;
}

.emoji-color {
  color: #a6a6a6 !important;
}

.send-group-card {
  box-shadow: none;
}

.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}

.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}

.actions-icon {
  font-size: 14px !important;
}

.actions-var a {
  cursor: pointer;
}

.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}

.ul-list-groups {
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: .725rem;
}

.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

/* .send-opt li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
} */

.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
}

.template-style {
  font-size: 0.8rem;
}

@media (min-width: 768px) {

  .v-select::v-deep>div:first-child,
  .campaign-input {
    min-width: 320px;
    max-width: 320px;
  }
}

.sample-area {}

.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group {
  margin-bottom: 3rem;
}

::v-deep label {
  color: var(--gray-font-color-2);
  font-weight: 400;
}

::v-deep .vs__search {
  position: absolute !important;
}

.preview-div {
  display: flex;
  position: relative;
  /* align-items: center; */
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}

hr {
  width: 100%;
  border-top: 2px solid #00000029;
}

.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}

@media (max-width: 767.98px) {
  .btn-import {
    margin-left: .5rem;
  }
}

.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}

.archive-opt {}

.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}

.recipient-row .form-control {
  border-color: transparent !important;
}

.calendar_form {
  position: absolute;
  right: 146px;
  width: 25px;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 25px;
}

.badge.badge-custom {
  background-color: var(--background-3) !important;
  color: var(--foreground) !important;
  border-radius: 25px !important;
  border-color: var(--gray-font-color);
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
  gap: .725rem;
  width: fit-content;

  .close {
    color: var(--foreground);
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }

  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    margin-top: 10%;
    z-index: -1;
  }

  .preview-wrapper {
    position: sticky;
    top: 100px;
  }

  .card-preview {
    position: absolute;
    width: 410px;
    top: 200px;
    right: 20%;
  }

  .card-preview .card-body {
    padding: 3rem 1rem;
  }

  .form-file {
    min-width: 320px;
    max-width: 320px;
  }
}

/* Card-tabs mobile */
@media (max-width: 767.98px) {
  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.active span) {
    display: none;
  }

  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }

  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }

  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }

  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }

  .form-file {
    width: 80%;
  }
}
</style>
